
import { defineComponent, ref } from "vue";
import { activeActivity } from "@/plugins/i18n";
import { useI18n } from "vue-i18n";
import moment, { Moment } from "moment";

export default defineComponent({
  name: "RapportFilter",
  props: [
    "startAt",
    "endAt",
    "total",
    "label",
    "loading",
    "tooltip",
    "symbol",
    "period",
  ],
  emits: [
    "update:startAt",
    "update:endAt",
    "validate",
    "emptySequence",
    "update:period",
  ],
  setup(_, { emit }) {
    const { t } = useI18n();
    function formatMom(mom: Moment) {
      return mom.format("yyyy-MM-DD");
    }
    const periods = [
      {
        label: t("rapport.today"),
        value: [formatMom(moment().startOf("day")), moment().toDate()],
        index: 0,
      },
      {
        label: t("rapport.thisWeek"),
        value: [
          formatMom(moment().startOf("isoWeek")),
          moment().endOf("isoWeek").toDate(),
        ],
        index: 1,
      },
      {
        label: t("rapport.thisMonth"),
        value: [
          formatMom(moment().startOf("month")),
          moment().endOf("month").toDate(),
        ],
        index: 2,
      },
      {
        label: t("rapport.three3LastMonth"),
        value: [
          formatMom(moment().subtract(3, "months").startOf("month")),
          moment().endOf("month").toDate(),
        ],
        index: 3,
      },
      {
        label: t("rapport.sixLastMonth"),
        value: [
          formatMom(moment().subtract(6, "months").startOf("month")),
          moment().endOf("month").toDate(),
        ],
        index: 4,
      },
      {
        label: t("rapport.thisYear"),
        value: [],
        index: 5,
      },
      {
        label: t("rapport.allPeriods"),
        value: [],
        index: 6,
      },
    ];
    const selected = ref(-1);
    function onSelect(index: number) {
      if (isNaN(index)) return;
      switch (index) {
        case 5:
          emit("update:period", "THIS_YEAR");
          break;
        case 6:
          emit("update:period", "ALL_PERIOD");
          break;
        default:
          emit("update:period", null);
          emit("update:startAt", periods[index].value[0]);
          emit("update:endAt", periods[index].value[1]);
          break;
      }
    }
    return {
      currencySymbol: activeActivity.value.currencySymbol,
      periods,
      selected,
      onSelect,
      onOpenTab(tab: number) {
        if (tab !== 0) emit("emptySequence");
        if (tab !== 1) emit("update:period", null);
      },
    };
  },
});
