import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-09831554"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  style: {"height":"100%"},
  class: "p-d-flex p-flex-wrap-reverse p-align-end"
}
const _hoisted_2 = { class: "p-col-12 p-md-10 full-height p-pt-2" }
const _hoisted_3 = { class: "p-field" }
const _hoisted_4 = { for: "dateA" }
const _hoisted_5 = { class: "p-field" }
const _hoisted_6 = { for: "dateB" }
const _hoisted_7 = { class: "p-tag" }
const _hoisted_8 = { class: "p-text-center p-py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_NumberFormatter = _resolveComponent("NumberFormatter")!
  const _component_SplitterPanel = _resolveComponent("SplitterPanel")!
  const _component_Splitter = _resolveComponent("Splitter")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "table", {}, undefined, true)
    ]),
    _createVNode(_component_Splitter, {
      style: {"overflow-x":"auto"},
      class: "p-col-12 p-md-2 p-mt-2 p-pb-0 full-height"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SplitterPanel, { class: "p-fluid p-p-1" }, {
          default: _withCtx(() => [
            _createVNode(_component_Accordion, {
              onTabOpen: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onOpenTab($event.index))),
              class: "p-mb-3",
              style: {"overflow-x":"auto"},
              activeIndex: 0
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AccordionTab, {
                  header: _ctx.$t('rapport.b2Date')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("dateA")), 1),
                      _createVNode(_component_Calendar, {
                        dateFormat: _ctx.$t('format.calLong'),
                        style: {"min-width":"200px"},
                        "show-button-bar": "",
                        id: "dateA",
                        "hide-on-date-time-select": "",
                        "show-time": "",
                        "show-seconds": "",
                        "model-value": _ctx.startAt,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:startAt', $event)))
                      }, null, 8, ["dateFormat", "model-value"])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("dateB")), 1),
                      _createVNode(_component_Calendar, {
                        dateFormat: _ctx.$t('format.calLong'),
                        style: {"min-width":"200px"},
                        "show-button-bar": "",
                        id: "dateB",
                        "hide-on-date-time-select": "",
                        "show-time": "",
                        "show-seconds": "",
                        "model-value": _ctx.endAt,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:endAt', $event))),
                        "min-date": new Date(_ctx.startAt)
                      }, null, 8, ["dateFormat", "model-value", "min-date"])
                    ])
                  ]),
                  _: 1
                }, 8, ["header"]),
                _createVNode(_component_AccordionTab, {
                  header: _ctx.$t('rapport.byPeriod')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Dropdown, {
                      modelValue: _ctx.selected,
                      "onUpdate:modelValue": [
                        _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selected) = $event)),
                        _ctx.onSelect
                      ],
                      "show-clear": "",
                      "option-value": "index",
                      options: _ctx.periods,
                      optionLabel: "label",
                      style: {"max-width":"200px"},
                      placeholder: _ctx.$t('rapport.selectPeriod')
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["header"]),
                (_ctx.$slots.default)
                  ? (_openBlock(), _createBlock(_component_AccordionTab, {
                      key: 0,
                      header: _ctx.$t('rapport.bySequence')
                    }, {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                      ]),
                      _: 3
                    }, 8, ["header"]))
                  : _createCommentVNode("", true)
              ]),
              _: 3
            }),
            _createVNode(_component_Button, {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('validate'))),
              label: _ctx.$t('validate'),
              icon: "pi pi-database",
              class: "p-button-rounded p-button-outlined",
              loading: _ctx.loading
            }, null, 8, ["label", "loading"]),
            (_ctx.label !== 'none')
              ? (_openBlock(), _createBlock(_component_Divider, {
                  key: 0,
                  layout: "horizontal",
                  align: "center"
                }, {
                  default: _withCtx(() => [
                    _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_7, [
                      _createTextVNode(_toDisplayString(_ctx.label || _ctx.$t("rapport.total")), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        {
              value: _ctx.tooltip,
              disabled: !_ctx.tooltip,
            },
                        void 0,
                        { bottom: true }
                      ]
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              (_ctx.total !== undefined)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_NumberFormatter, { value: _ctx.total }, null, 8, ["value"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.symbol === undefined ? _ctx.currencySymbol : ""), 1)
                  ], 64))
                : _renderSlot(_ctx.$slots, "total", { key: 1 }, undefined, true)
            ])
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ]))
}