import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paginator = _resolveComponent("Paginator")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createBlock(_component_MultiSelect, {
    "model-value": _ctx.modelValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    options: _ctx.options,
    "option-value": "id",
    "option-label": "label",
    display: "chip",
    "data-key": "id",
    ref: "sequencesRef",
    placeholder: _ctx.$t('rapport.chooseSequence'),
    loading: _ctx.loading,
    "filter-placeholder": _ctx.$t('rapport.chooseSequence'),
    filter: true,
    style: {"max-width":"400px"}
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Paginator, {
        rows: _ctx.sequences.meta.itemCount,
        onPage: _ctx.setPage,
        "always-show": true,
        first: _ctx.firstPage,
        "onUpdate:first": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstPage) = $event)),
        totalRecords: _ctx.sequences.meta.totalItems
      }, null, 8, ["rows", "onPage", "first", "totalRecords"])
    ]),
    _: 1
  }, 8, ["model-value", "options", "placeholder", "loading", "filter-placeholder"]))
}