
import { defineComponent, reactive, ref } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { activeActivity } from "@/plugins/i18n";
import {
  QuerySequencesPaginateArgs,
  Sequence,
  SequencePaginateInput,
  SequencePagination,
} from "@/graphql/types";
import { InitialPagination, PAGINATION_META } from "@/graphql/utils/pagination";
import { gql } from "@apollo/client";
import { SEQUENCE_FIELDS } from "@/graphql/sequence/sequence";

type SequencesPaginateData = {
  sequencesPaginate: SequencePagination;
};

const SEQUENCE_PAGINATION = gql`
    query SequencesPaginate($input: SequencePaginateInput!) {
        sequencesPaginate(input: $input) {
            items{
              ${SEQUENCE_FIELDS}
              terminal{id name}
            }
            ${PAGINATION_META}
        }
    }
`;

export default defineComponent({
  name: "SequencesSelect",
  props: ["modelValue"],
  emits: ["update:modelValue"],
  setup(_, { emit }) {
    const input = reactive<SequencePaginateInput>({
      activityId: activeActivity.value.id,
      page: 1,
      limit: 5,
    });
    const sequencesRef = ref(null);
    const loadedPages = new Set<number>();

    const options = ref([]);

    function addOptions(items?: Sequence[]) {
      if (!loadedPages.has(input.page) && items?.length) {
        const more = items
          .map((it) => ({
            id: it.id,
            label: `${it.number} - ${it.terminal.name}`,
          }))
          .concat([...options.value]);
        more.sort((a: any, b: any) => {
          const idA = Number(a.label.split(" ")[0]);
          const idB = Number(b.label.split(" ")[0]);
          return idB - idA;
        });
        options.value = more;
        loadedPages.add(input.page);
      }
    }

    const { loading, result, onResult } = useQuery<
      SequencesPaginateData,
      QuerySequencesPaginateArgs
    >(SEQUENCE_PAGINATION, { input });
    const sequences = useResult<
      SequencesPaginateData,
      SequencePagination,
      SequencePagination
    >(result, InitialPagination, (res) => {
      if (!options.value.length) addOptions(res?.sequencesPaginate?.items);
      return {
        ...res.sequencesPaginate,
        items: [],
      };
    });

    const firstPage = ref(0);

    onResult(({ data }) => {
      if (input.page > 1) sequencesRef.value.show();
      addOptions(data?.sequencesPaginate?.items);
    });
    /*function moreValues(event: any) {
      input.page = event.page + 1;
      if (loadedPages.has(input.page)) return;
      else loadedPages.add(input.page);
      fetchMore({
        variables: { input: { ...input } },
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          if (!fetchMoreResult?.sequencesPaginate) return previousQueryResult;
          return {
            ...fetchMoreResult,
            sequencesPaginate: {
              ...fetchMoreResult.sequencesPaginate,
              items: [
                ...previousQueryResult.sequencesPaginate.items,
                ...fetchMoreResult.sequencesPaginate.items,
              ],
            },
          };
        },
      });
    }*/
    function setPage(event: any) {
      const page = event.page + 1;
      if (input.page != page) {
        input.page = page;
      }
    }

    return {
      input,
      sequences,
      loading,
      setPage,
      sequencesRef,
      firstPage,
      options,
    };
  },
});
