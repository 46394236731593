import { printFrame } from "@/graphql/ticket/ticket.printer";

export const statusColors = [
  "#4caf50",
  "#009688",
  "#00BCD4",
  "#4caf50",
  "#FBC02D",
  "#E91E63",
  "#3F51B5",
];

export function printRapport(data: string) {
  const body = `
      <!DOCTYPE html>
      <html>
        <body>
         ${data}
        </body>
        <style>
           .separator {
              border-bottom: 3px dashed gainsboro;
            }
           table {
                width: 100%;
                text-align: center;
                font-size: 10px;
                border-collapse:collapse;
                table-layout: fixed;
           }
           tfoot {
              display: table-row-group;
           }
           td, th {
               width: 50%;
               border: 1px solid black;
               padding: 8px;
           }
          .p-no-border { border: unset !important; }
          .p-text-left {text-align: left !important;}
          .p-text-right {text-align: right !important;}
          .p-text-center {text-align: center !important;}
          .p-text-uppercase {text-transform: uppercase !important;}
          .p-jc-center {justify-content: center;}
          .p-ai-center {align-items: center;}
          .p-d-flex {display: flex !important;}
          .p-my-1 {margin-top: 0.25rem !important;margin-bottom: 0.25rem !important;}
          .p-my-2 {margin-top: 0.5rem !important;margin-bottom: 0.5rem !important;}
          .p-my-3 {margin-top: 1rem !important;margin-bottom: 1rem !important;}
          .p-py-2 {padding-top: 0.5rem !important; padding-bottom: 0.5rem !important;}
            @media print {
              @page {
                size: 21cm 29.7cm;
                margin: 0.2cm;
              }
           }
        </style>
      </html>`;
  printFrame(body);
}
